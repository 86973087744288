import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "./menu"
import logo from "../images/BigLogo.png"

const Header = () => (
  <header>
    <div className="header-logo">
      <Link to="/" style={{ alignSelf: "center" }}>
        <img className="header-logo__img" src={logo} alt="" />
      </Link>
    </div>
    <Menu />
    <div className="header-social">
      <ul className="social-links">
        <li className="linkedin">
          <a
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/almalavinia"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              viewBox="0 0 512 512"
            >
              <title>Instagram</title>
              <g>
                <g>
                  <path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160 C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48 h192c61.76,0,112,50.24,112,112V352z"></path>
                </g>
              </g>
              <g>
                <g>
                  <path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336 c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"></path>
                </g>
              </g>
              <g>
                <g>
                  <circle cx="393.6" cy="118.4" r="17.056"></circle>
                </g>
              </g>
            </svg>
          </a>
        </li>
        <li className="instagram">
          <a
            rel="noopener noreferrer"
            href="https://www.instagram.com/travelalma/"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              viewBox="0 0 430.117 430.117"
            >
              <title>LinkedIn</title>
              <path
                d="M430.117,261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707
 c-25.473,0-40.632,17.142-47.301,33.724c-2.432,5.928-3.058,14.179-3.058,22.477V420.56h-92.219c0,0,1.242-251.285,0-277.32h92.21
 v39.309c-0.187,0.294-0.43,0.611-0.606,0.896h0.606v-0.896c12.251-18.869,34.13-45.824,83.102-45.824
 C384.633,136.724,430.117,176.361,430.117,261.543z M52.183,9.558C20.635,9.558,0,30.251,0,57.463
 c0,26.619,20.038,47.94,50.959,47.94h0.616c32.159,0,52.159-21.317,52.159-47.94C103.128,30.251,83.734,9.558,52.183,9.558z
 M5.477,420.56h92.184v-277.32H5.477V420.56z"
              ></path>
            </svg>
          </a>
        </li>
        <li className="twitter">
          <a
            rel="noopener noreferrer"
            href="https://twitter.com/almalavinia"
            target="_blank"
          ></a>
        </li>
      </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
