import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Menu = ({ menu }) => {
  return(
      <nav>
      {menu && menu.map(menuItem => {
        return(
          <li key={menuItem.id}><Link data-hover={menuItem.title} to={menuItem.linkPath}>{menuItem.title}</Link></li>
        )
      })}
      </nav>

    )
}

Menu.propTypes = {
  siteTitle: PropTypes.string,
}

Menu.defaultProps = {
  menu : [
    {
      id:1,
      title:"Home",
      linkPath:'/',
    },
    {
      id:2,
      title:"About",
      linkPath:'/about/',
    },
    {
      id:3,
      title:"Experience",
      linkPath:'/experience/',
      },
    {
      id:4,
      title:"Services",
      linkPath:'/services/',
      },
    {
      id:5,
      title:"Contact",
      linkPath:'/contact/',
      }
]
}

export default Menu
