/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import Header from "./header";
import Aside from "./aside";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const handleClick = () =>  {
    var wrapper = document.querySelector('.wrapper')
    var aside = document.querySelector('aside')
    if (wrapper.style.transform === 'translate3d(-80%, 0px, 0px)') {
      wrapper.style.transform = 'translate3d(0px, 0px, 0px)'
      wrapper.classList.remove("theclass")
      aside.style.display = 'none'
    } else {
      wrapper.classList.add("theclass")
      wrapper.style.transform = 'translate3d(-80%, 0px, 0px)'
      aside.style.display = 'flex'
    }

  }
  return (
    <>
      <Aside/>
      <div className="wrapper">
        <div onClick={handleClick} className="menu-toggle-wrapper">
          <div className="menu-toggle"></div>
          <div className="trigger"></div>
          <div className="burger"></div>
        </div>
        <Header/>
        <div className="main-wrapper">
          <main>{children}</main>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
