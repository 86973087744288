import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Aside = ({ aside }) => {
  return(
      <aside>
      {aside && aside.map(asideItem => {
        return(
          <Link key={asideItem.id} data-hover={asideItem.title} to={asideItem.linkPath}>{asideItem.title}</Link>
        )
      })}
      </aside>
    )
}

Aside.propTypes = {
  siteTitle: PropTypes.string,
}

Aside.defaultProps = {
  aside : [
    {
      id:1,
      title:"Home",
      linkPath:'/',
    },
    {
      id:2,
      title:"About",
      linkPath:'/about',
    },
    {
      id:3,
      title:"Experience",
      linkPath:'/experience',
      },
    {
      id:4,
      title:"Services",
      linkPath:'/services',
      },
    {
      id:5,
      title:"Contact",
      linkPath:'/contact',
      }
]
}

export default Aside
